import { MenuItem } from '@mui/material';
import { Button, Input } from 'easyship-components';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// Models
import { ControlledInput } from '@/components/ControlledInput';
import CountryFlag from '@/components/CountryFlag';
import Select from '@/components/Select';
import { toastError } from '@/components/Toastify';
import { Toggle } from '@/components/Toggle';
import { Country } from '@/core/models/Address';
import { FormData } from '@/core/models/User';
import { EMAIL_REGEX } from '@/core/regex';
// Hooks
import useLoginMutation from '@/hooks/useLoginMutation';
import useResetPasswordMutation from '@/hooks/useResetPasswordMutation';
import useValidateUserMutation from '@/hooks/validation/useValidateUserMutation';
// Components

interface AuthPageProps {
  stepId: string;
  countries: Country[];
  onValidated: () => void;
  recaptchaToken: string;
}

export const UserPage = ({ stepId, countries, onValidated, recaptchaToken }: AuthPageProps) => {
  const { mutateAsync: loginMutateAsync } = useLoginMutation();
  const { mutateAsync: validationMutateAsync } = useValidateUserMutation();
  const { mutateAsync: resetPasswordMutateAsync } = useResetPasswordMutation();
  const { control, handleSubmit, getValues, setValue, setError } = useFormContext<FormData>();
  setValue('user.id', stepId);

  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState('signup');
  const selection = [
    { id: 'signup', value: 'Sign Up' },
    { id: 'login', value: 'Log In' },
  ];

  const toggle = () => {
    setSelected((prevState) => (prevState === 'signup' ? 'login' : 'signup'));
  };

  const login = async () => {
    const data = await loginMutateAsync(
      {
        user: {
          email: getValues('user.email'),
          password: getValues('user.password'),
        },
        token: recaptchaToken,
      },
      {
        onError: (err) => {
          toastError(err?.response?.data.status);
        },
      },
    );
    // Set mandatory values for address form with user data
    data?.name && setValue('address.name', data.name);
    data?.email && setValue('address.email', data.email);
    data?.country && setValue('address.country', data.country);
    data?.companyName && setValue('address.companyName', data.companyName);
  };

  const signup = async () => {
    const response = await validationMutateAsync(getValues('user'), {
      onError: (err) => {
        if (err.response) {
          err?.response?.data.errors.forEach((error: string) => {
            toastError(error);
          });
        } else {
          toastError();
        }
      },
    });
    if (response === 200) {
      // Set mandatory values for address form without requesting them from the user twice
      setValue('address.name', getValues(['user.firstName', 'user.lastName']).join(' '));
      setValue('address.country', getValues('user.country'));
      setValue('address.email', getValues('user.email'));
      setValue('address.companyName', getValues('user.companyName'));
      onValidated();
    }
  };

  const onButtonClick = async () => {
    setIsLoading(true);
    selected === 'signup' ? signup() : login();
    setIsLoading(false);
  };

  const resetPassword = () => {
    const email = getValues('user.email');
    if (!email) {
      setError('user.email', {
        type: 'required',
        message: 'An email is required in order to reset your password.',
      });
    } else {
      resetPasswordMutateAsync(
        { email, token: recaptchaToken },
        {
          onError: (err) => {
            toastError(err?.response?.data.status);
          },
        },
      ).catch((err) => undefined);
    }
  };

  return (
    <>
      <div className="mx-3 my-6">
        <Toggle handleChange={toggle} selected={selected} selection={selection} />
      </div>

      <div className="grid grid-cols-2 mb-5 gap-x-2 gap-y-3">
        <div className="col-span-2">
          <ControlledInput
            label="Email"
            maxLength={50}
            control={control}
            name="user.email"
            onEnter={handleSubmit(onButtonClick)}
            rules={{
              required: { value: true, message: 'This field is required.' },
              pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email.' },
            }}
          />
        </div>
        <div className="col-span-2">
          <ControlledInput
            label="Password"
            type="password"
            maxLength={50}
            control={control}
            name="user.password"
            onEnter={handleSubmit(onButtonClick)}
            helperText="Password must be at least 8 characters."
            rules={{
              required: { value: true, message: 'This field is required.' },
              minLength: 8,
            }}
          />
        </div>
        {selected === 'login' && (
          <p className="text-sm text-blue-500 cursor-pointer" onClick={resetPassword}>
            Forgot password?
          </p>
        )}
        {selected === 'signup' && (
          <>
            <ControlledInput
              label="First Name"
              control={control}
              name="user.firstName"
              onEnter={handleSubmit(onButtonClick)}
              rules={{ required: { value: true, message: 'This field is required.' } }}
            />
            <ControlledInput
              label="Last Name"
              control={control}
              name="user.lastName"
              rules={{ required: { value: true, message: 'This field is required.' } }}
            />
            <ControlledInput
              label="Company Name"
              control={control}
              name="user.companyName"
              onEnter={handleSubmit(onButtonClick)}
              rules={{ required: { value: true, message: 'This field is required.' } }}
            />
            <Controller
              control={control}
              rules={{ required: { value: true, message: 'This field is required.' } }}
              render={({ field: { ref, onChange, value }, fieldState }) =>
                countries.length > 0 ? (
                  <Select
                    inputRef={ref}
                    label="Country"
                    value={value || -1}
                    onChange={onChange}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  >
                    <MenuItem disabled value={-1}>
                      <p className="text-ink-100">Choose one</p>
                    </MenuItem>
                    {countries?.map((country: Country) => (
                      <MenuItem key={country.id} value={country.id}>
                        <CountryFlag alpha2={country.alpha2} />
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Input
                    label="Country"
                    status={fieldState.error ? 'error' : 'default'}
                    ref={ref}
                    statusText={fieldState.error?.message}
                  />
                )
              }
              name="user.country"
            />
          </>
        )}
      </div>
      <Button
        onClick={handleSubmit(onButtonClick)}
        color="primary"
        className="w-full"
        loading={isLoading}
      >
        Continue
      </Button>
    </>
  );
};
