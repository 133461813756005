import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { toastError } from '@/components/Toastify';
import { AuthenticationPayload } from '@/pages/auth/authentication/types';
import { useAuthGateways } from '@/pages/auth/contexts/AuthGatewayProviders';
import { AuthError, AuthErrorData } from '@/pages/auth/types';
import { parse } from '@/pages/auth/utils/errorParser';

interface VerifyAuthenticateMutationProps {
  handleError: (data: AuthError) => void;
}

export default function useVerifyAuthenticateMutation({
  handleError,
}: VerifyAuthenticateMutationProps) {
  const { authGateway } = useAuthGateways();

  return useMutation({
    mutationFn: (payload: AuthenticationPayload) => authGateway.verifyAuthenticate(payload),
    onError: (error: AxiosError<AuthErrorData>) => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          handleError(parse(error.response.data));
        } else if (error.response.status === 404) {
          toastError(error.message);
        } else {
          toastError();
        }
      }
    },
  });
}
