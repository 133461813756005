import { Alert, Button } from 'easyship-components';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ControlledInput } from '@/components/ControlledInput';
import { toastSuccess } from '@/components/Toastify';
import useRecaptcha from '@/hooks/useRecaptcha';
import { ResendOtpButton } from '@/pages/auth/authentication/components/ResendOtpButton';
import useVerifyAuthenticateMutation from '@/pages/auth/authentication/hooks/useVerifyAuthenticateMutation';
import {
  AuthenticationFormData,
  AuthenticationPayload,
  MfaErrorCode,
} from '@/pages/auth/authentication/types';
import { AuthError } from '@/pages/auth/types';
import { LocalStorageExpiry } from '@/pages/auth/utils/LocalStorageExpiry';

const IS_DEVELOPMENT = import.meta.env.VITE_ENV_NAME !== 'production';

export const AuthenticationForm = () => {
  const { control, handleSubmit } = useForm<AuthenticationFormData>();
  const { recaptchaToken, fetchAndSetRecaptcha } = useRecaptcha();
  const [apiError, setApiError] = useState<string | undefined>();
  const navigate = useNavigate();

  const localStorage = new LocalStorageExpiry();

  const handleError = (data: AuthError) => {
    if (data.code === MfaErrorCode.INVALID_CODE) {
      // refresh mfaToken
      if (data.mfaToken) {
        Cookies.set('mfaToken', data.mfaToken, {
          domain: window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com',
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
      }
      setApiError('The code you entered is wrong or expired. Try again or resend the code.');
    } else if (data.code === MfaErrorCode.FAILED || MfaErrorCode.INVALID_MFA_TOKEN) {
      navigate('/auth/login', { state: { error: data.message } });
    }
    fetchAndSetRecaptcha();
  };

  const { mutateAsync: verifyMutateAsync, isPending } = useVerifyAuthenticateMutation({
    handleError,
  });
  const credentialsEnv = import.meta.env.VITE_APP_CREDENTIALS;

  const handleOnSubmit: SubmitHandler<AuthenticationFormData> = (data) => {
    setApiError(undefined);

    const MFA_TOKEN = Cookies.get('mfaToken');

    const payload: AuthenticationPayload = {
      code: data.code,
      mfaToken: MFA_TOKEN ?? '',
      recaptchaToken,
    };

    verifyMutateAsync(payload, {
      onSuccess: (data) => {
        const cookieDomain =
          window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
        if (Cookies.get(credentialsEnv)) removeCredentials();
        Cookies.set(credentialsEnv, data.session.token, {
          domain: cookieDomain,
          secure: true,
          sameSite: 'none',
          partitioned: true,
          path: '/',
        });
        // remove all of the data in localStorage & cookie (mfaToken) on verify success, not needed anymore
        localStorage.remove('expiresAt');
        localStorage.remove('resendAllowedAt');
        Cookies.remove('mfaToken', {
          secure: true,
          sameSite: 'none',
          partitioned: true,
          domain: cookieDomain,
          path: '/',
        });

        toastSuccess('Success verifying your account. Redirecting...');
        setTimeout(() => {
          window.location.replace(
            `https://app${IS_DEVELOPMENT ? `-${import.meta.env.VITE_ENV_NAME}` : ''}.easyship.com/dashboard`,
          );
        }, 500);
      },
    });
  };

  function removeCredentials(): void {
    const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';
    Cookies.remove(credentialsEnv, {
      secure: true,
      sameSite: 'none',
      partitioned: true,
      domain: cookieDomain,
      path: '/',
    });
  }

  return (
    <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(handleOnSubmit)}>
      <ControlledInput
        label="Verification Code"
        maxLength={50}
        control={control}
        name="code"
        rules={{
          minLength: { value: 6, message: 'The code must be 6 digits.' },
          maxLength: { value: 6, message: 'The code must be 6 digits.' },
          required: { value: true, message: 'This field is required.' },
        }}
      />
      {/* Wait until recaptcha Token is initialized */}
      {recaptchaToken && (
        <ResendOtpButton
          onSendOtpFailed={fetchAndSetRecaptcha}
          onSendOtpSucceed={fetchAndSetRecaptcha}
          recaptchaToken={recaptchaToken}
        />
      )}
      {/* Only shows when it returned error from API */}
      {apiError && (
        <Alert severity="error" className="w-full">
          {apiError}
        </Alert>
      )}
      <Button
        loading={isPending}
        disabled={isPending}
        className="px-10"
        type="submit"
        color="primary"
      >
        Complete Login
      </Button>
    </form>
  );
};
